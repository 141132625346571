import React from 'react'
import LayoutUsers from '../../components/Layouts/LayoutUsers'
import { connect } from 'react-redux'
import { usersCreateRequest, usersCreateSuccess, usersCreateError } from './usersSlice'
import { services } from '../../App'
import { store } from '../../index.js' // TODO: clean this up with redux thunk or someting else

const createUser = async (data) => {
    try {
        data.password = 'a'
        store.dispatch(usersCreateRequest())
        const result = await services.users.create(data)
        console.log('result', result)
        await store.dispatch(services.users.find()) // TODO: use result
        store.dispatch(usersCreateSuccess())
    } catch(e) {
        console.log('error', e)
        store.dispatch(usersCreateError(e.message))
    }
}

const Users = (props) => {
    return (
        <div>
            <LayoutUsers
                createUser={data => createUser(data)}
                users={props.users.queryResult.data || props.users.queryResult}
                />
        </div>
    )
}

const mapState = (state) => ({
    users: state.users
})

export default connect(
    mapState
  )(Users)