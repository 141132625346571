import React, { Component } from 'react'

import Login from './features/Login/Login'
import Boot from './features/Login/Boot'
import Firstadmin from './features/Login/Firstadmin'
import Users from './features/Users/Users'
// import Requests from './features/Requests/Requests'
import feathers from "@feathersjs/client"
import io from 'socket.io-client'

import { combineReducers } from 'redux'
import loginReducer from './features/Login/loginSlice'
import usersControlReducer from './features/Users/usersSlice'
import reduxifyServices from 'feathers-redux'

import { connect } from 'react-redux'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


// Establish a Socket.io connection
const urlApiUsers = process.env.REACT_APP_API_USERS || 'http://localhost:3030'
console.log('urlApiUsers >> ', urlApiUsers)

const socket = io(urlApiUsers)
// Initialize our Feathers client application through Socket.io
// with hooks and authentication.
export const client = feathers()


client.configure(feathers.socketio(socket))
// Use localStorage to store our login token
client.configure(feathers.authentication())

// Create Redux actions and reducers for Feathers services
export const services = reduxifyServices(client, ['users', 'firstadmin'])

export const rootReducer = combineReducers({
  login: loginReducer,
  usersControl: usersControlReducer,
  users: services.users.reducer,
  firstadmin: services.firstadmin.reducer,

})

export class AppBase extends Component {
  render() {
    return (
      <div>
        <Login client={client} />
        {this.props.loginStatus === 'logged'
          ? (<Router>
          { /*<Link to="/">[Home]</Link>
          <Link to="/users">[Users]</Link>
          <Link to="/requests">[Requests]</Link>
          <Link to="/commitments">[Commitments]</Link> */}
          <Switch>
            <Route exact path="/">
              <Redirect to="/users" />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route exact path="/">
              <Redirect to="/users" /> 
            </Route>
            </Switch>
          </Router>)
          : null }
        <Boot client={client} />
        <Firstadmin client={client} />
      </div>
    )
  }
}

const mapState = (state) => ({
  loginStatus: state.login.status
})

export const App = connect(
  mapState
)(AppBase)