import { createSlice } from '@reduxjs/toolkit'

// TODO: cleanup local storage
// TODO: check token !!!
const usersSlice = createSlice({
  name: 'usersControl',
  initialState: {},
  reducers: {
    usersCreateRequest(state, action) {
        return {
            ...state,
            create: 'creating'
        }
    },
    usersCreateSuccess(state, action) {
        return {
            ...state,
            create: 'done'
        }
    },
    usersCreateError(state, action) {
        const { errorMessage } = action.errorMessage
        state.push({ create: 'error', createError: errorMessage })
    }
  }
})

export const { 
    usersCreateRequest, usersCreateSuccess, usersCreateError
 } =  usersSlice.actions

export default usersSlice.reducer