import React from 'react'
import LayoutLogin from '../../components/Layouts/LayoutLogin'
import LayoutRegisterFirstAdmin from '../../components/Layouts/LayoutRegisterFirstAdmin'
import { connect } from 'react-redux'
import { loginRequest, loginSuccess, loginError,
    logoutRequest, logoutSuccess, logoutError,
    firstadminCreateRequest, firstadminCreateSuccess, firstadminCreateError 
 } from './loginSlice'
import { client, services } from '../../App'
import { store } from '../../index.js' // TODO: clean this up with redux thunk or someting else
import TopBar from '../../components/Menus/TopBar'

const mapDispatch = { loginRequest, loginSuccess, loginError, 
    logoutRequest, logoutSuccess, logoutError,
    firstadminCreateRequest, firstadminCreateSuccess, firstadminCreateError }
    

const loginJWT = async (token, client) => {
    try {
        store.dispatch(loginRequest())
        const data = await client.authenticate({
            strategy: 'jwt',
            accessToken: token
          })
        // TODO: clean up local storage
        localStorage.setItem('token', data.accessToken)
        store.dispatch(loginSuccess(data.accessToken))
    } catch(e) {
        console.log('error', e)
        store.dispatch(loginError(e.message))
    }
}

const login = async (credentials, client) => {
    try {
        store.dispatch(loginRequest())
        const data = await client.authenticate({
            strategy: 'local',
            ...credentials
          })
        // TODO: clean up local storage
        localStorage.setItem('token', data.accessToken)
        store.dispatch(loginSuccess(data.accessToken))
    } catch(e) {
        console.log('error', e)
        store.dispatch(loginError(e.message))
    }
}

const firstadminCreate = async (data) => {
    try {
        store.dispatch(firstadminCreateRequest())
        const result = await services.firstadmin.create(data)
        console.log('result', result)
        await store.dispatch(services.firstadmin.find()) // TODO: use result
        // TODO: clean up local storage
        store.dispatch(firstadminCreateSuccess())
    } catch(e) {
        console.log('error', e)
        store.dispatch(firstadminCreateError(e.message))
    }
}

const logout = async () => {
    try {
        localStorage.removeItem('token')
        store.dispatch(logoutRequest())
        await client.services.authentication.remove(null)
        // TODO: clean up redux
        // TODO: clean up local storage
        store.dispatch(logoutSuccess())
    } catch(e) {
        console.log('error', e)
        store.dispatch(loginError(e.message))
    }
}

const Login = (props) => {
    if(props.status === 'logged') { 
        return <TopBar onLogout={creds => logout()} />
        // return (<LayoutLogout onLogout={creds => logout()} />)
    }

    if(props.firstadminFind !== 'done') { 
        return (<div> Checking first admin ...</div>)
    }

    if(props.firstadmin.queryResult.done !== true) { 
        return (<div>
            <LayoutRegisterFirstAdmin
                status={props.status}
                onFirstadminCreate={data => firstadminCreate(data, props.client) }
                />
        </div>)
    }

    if(props.status == null  && props.token !== null) { 
        setTimeout(() => loginJWT(props.token, props.client), 10) // TODO: dirty to avoid the warning ... fix em plz
        return (<div> Checking token ...</div>)
    }

    return (
        <div>
            <LayoutLogin
                status={props.status}
                onLogin={creds => login(creds, props.client) }
                />
        </div>
    )
}

const mapState = (state) => ({
    status: state.login.status,
    token: state.login.token,
    users: state.users,
    firstadmin: state.firstadmin,
    firstadminFind: state.login.firstadminFind
})

export default connect(
    mapState,
    mapDispatch
  )(Login)