import { createSlice } from '@reduxjs/toolkit'

// TODO: cleanup local storage
// TODO: check token !!!
const localStorageToken = localStorage.getItem('token') 

const loginSlice = createSlice({
  name: 'login',
  initialState: {
      token: localStorageToken ? localStorageToken : ''
    },
  reducers: {
    loginRequest(state, action) {
        return {
            ...state,
            status: 'logging'
        }
    },
    loginSuccess: {
        reducer(state, action) {
            const { token } = action.payload
            return {
                ...state,
                status: 'logged',
                token
            }
        },
        prepare(token) {
            return { payload: { token } }
        }
    },
    loginError: {
        reducer(state, action) {
            const { errorMessage } = action.payload
            return {
                ...state,
                status: 'error',
                token: null,
                errorMessage
            }
        },
        prepare(errorMessage) {
            return { payload: { errorMessage } }
        }
    },
    logoutRequest(state, action) {
        return {
            ...state,
            status: 'logging out'
        }
    },
    logoutSuccess(state, action) {
        return {
            ...state,
            status: null,
            token: null,
            boot: "none"
        }
    },
    logoutError(state, action) {
        const { errorMessage } = action.errorMessage
        state.push({ status: 'logged', error: errorMessage, })
    },
    bootRequest(state, action) {
        return {
            ...state,
            boot: 'booting'
        }
    },
    bootSuccess(state, action) {
        return {
            ...state,
            boot: 'booted'
        }
    },
    bootError(state, action) {
        const { errorMessage } = action.errorMessage
        state.push({ boot: 'error', error: errorMessage })
    },
    firstadminFindRequest(state, action) {
        return {
            ...state,
            firstadmin: 'checking'
        }
    },
    firstadminFindSuccess(state, action) {
        return {
            ...state,
            firstadminFind: 'done'
        }
    },
    firstadminFindError(state, action) {
        const { errorMessage } = action.errorMessage
        state.push({ firstadmin: 'error', error: errorMessage })
    },
    firstadminCreateRequest(state, action) {
        return {
            ...state,
            firstadmin: 'creating'
        }
    },
    firstadminCreateSuccess(state, action) {
        return {
            ...state,
            firstadminFind: 'done'
        }
    },
    firstadminCreateError(state, action) {
        const { errorMessage } = action.errorMessage
        state.push({ firstadmin: 'error', error: errorMessage })
    }
  }
})

export const { loginRequest, loginSuccess, logoutError, 
    logoutRequest, logoutSuccess, loginError, 
    bootRequest, bootSuccess, bootError,
    firstadminRequest, firstadminFindSuccess, firstadminFindError,
    firstadminCreateRequest, firstadminCreateSuccess, firstadminCreateError
 } =  loginSlice.actions

export default loginSlice.reducer