// import React, { useState }  from 'react'
import { connect } from 'react-redux'
import { bootSuccess, bootError } from './loginSlice'
import { services } from '../../App'
import { store } from '../../index.js' // TODO: clean this up with redux thunk or someting else


const boot = async(client) => {
    try {
        await store.dispatch(services.firstadmin.find())
        await store.dispatch(services.users.find())
        const usersService = client.service('/users')
        usersService.on('created', (data) => { store.dispatch(services.users.onCreated(data)) })
        usersService.on('updated', (data) => { store.dispatch(services.users.onUpdated(data)) })
        usersService.on('patched', (data) => { store.dispatch(services.users.onPatched(data)) })
        usersService.on('removed', (data) => { store.dispatch(services.users.onRemoved(data)) })

        store.dispatch(bootSuccess())
    } catch(e) {
        console.log('error', e)
        store.dispatch(bootError(e.message))
    }
}


const Boot = (props) => {
    if(props.bootStatus === 'booted') { 
        return null
    }
    if(props.bootStatus  !== 'booting' && props.loginStatus === 'logged') { 
        setTimeout(() => boot(props.client), 10) // TODO: dirty to avoid the warning ... fix em plz
        return null
    }
    return null
}

const mapState = (state) => ({
    loginStatus: state.login.status,
    bootStatus: state.login.boot
})

export default connect(
    mapState
  )(Boot)