import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function LayoutRegisterFirstAdmin (props) {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Please register first admin
        </Typography>
          <TextField onChange={e => setEmail(e.target.value)} variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
          <TextField onChange={e => setFirstname(e.target.value)} variant="outlined" margin="normal" required fullWidth id="firstname" label="firstname" name="firstname" autoComplete="firstname" autoFocus />
          <TextField onChange={e => setLastname(e.target.value)} variant="outlined" margin="normal" required fullWidth id="lastname" label="lastname" name="lastname" autoComplete="lastname" autoFocus />
          <TextField onChange={e => setPassword(e.target.value)} variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
          <Button onClick={() => props.onFirstadminCreate({email, password, firstname, lastname})} type="submit" fullWidth variant="contained" color="primary" className={classes.submit}> Register Admin </Button>
      </div>
    </Container>
  )
}