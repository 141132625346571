// import React, { useState }  from 'react'
import { connect } from 'react-redux'
import { firstadminFindSuccess, firstadminFindError } from './loginSlice'
import { services } from '../../App'
import { store } from '../../index.js' // TODO: clean this up with redux thunk or someting else


const firstadmin = async(client) => {
    try {
        await store.dispatch(services.firstadmin.find())

        store.dispatch(firstadminFindSuccess())
    } catch(e) {
        console.log('error', e)
        store.dispatch(firstadminFindError(e.message))
    }
}


const Firstadmin = (props) => {
    if(props.firstadminStatus == null) { 
        setTimeout(() => firstadmin(props.client), 10) // TODO: dirty to avoid the warning ... fix em plz
        return null
    }
    return null
}

const mapState = (state) => ({
    firstadminStatus: state.login.firstadmin
})

export default connect(
    mapState
  )(Firstadmin)