import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { App, rootReducer } from './App';
import { configureStore } from '@reduxjs/toolkit'
// import { applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'

export const store = configureStore({ reducer: rootReducer , middleware: [promise, thunk]} )

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
